// CSS
import "./css/common.css";
import "./css/swiper.css";
import "./css/drawer.css";
import "./css/footer.css";
import "./css/hero.css";
import "./css/home.css";
import "./css/goroku.css";
import "./css/staff.css";
import "./css/news.css";
import "./css/philosophy.css";
import "./css/about.css";
import "./css/contact.css";
import "./css/future.css";
import "./css/info.css";
import "./css/recruit.css";
import "./css/channel.css";

// Font Awesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

// JS
import "./js/drawer";
import "./js/viewport";
import "./js/swiper";
import "./js/hover";
